import React, { createContext } from "react";

const ProjectsActiveFilter = createContext({
  projectsActiveFilter: "All",
  setProjectsActiveFilter: () => {},
});

export class ProjectsActiveFilterProvider extends React.Component {
  setProjectsActiveFilter = newProjectsActiveFilter => {
    this.setState({ projectsActiveFilter: newProjectsActiveFilter });
  };

  state = {
    projectsActiveFilter: "All",
    setProjectsActiveFilter: this.setProjectsActiveFilter,
  };

  render() {
    return (
      <ProjectsActiveFilter.Provider value={this.state}>
        {this.props.children}
      </ProjectsActiveFilter.Provider>
    );
  }
}

export const ProjectsActiveFilterConsumer = ProjectsActiveFilter.Consumer;
